import React from "react";

import { Icon } from '@types';

const AboutIcon: Icon = ({ fill }) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill={fill} stroke="none">
<path d="M241 469 c-26 -26 -31 -38 -31 -79 0 -41 5 -53 31 -79 26 -26 38 -31
79 -31 41 0 53 5 79 31 26 26 31 38 31 79 0 41 -5 53 -31 79 -26 26 -38 31
-79 31 -41 0 -53 -5 -79 -31z m136 -21 c51 -48 15 -138 -56 -138 -47 0 -81 32
-81 79 0 72 84 109 137 59z"/>
<path d="M219 210 c-36 -19 -99 -83 -99 -100 0 -20 21 -9 50 24 40 45 87 66
150 66 63 0 110 -21 150 -66 29 -33 50 -44 50 -24 0 18 -63 81 -101 101 -51
26 -150 25 -200 -1z"/>
</g>
</svg>

);

export default AboutIcon;
